<template>
	<v-dialog fullscreen v-model="popCustomerUpdate">
		<!-- contents -->
		<div class="u-dialog-header">
			<div class="header_wrap web">
				<header>
					<div class="header_area">
						<h1>coway</h1>
					</div>
				</header>
			</div>
		</div>
		<div class="u-dialog-wrap">
			<div class="cw-app-container">
				<div class="pre-subs-box01 expand">
					<h3>고객정보 업데이트</h3>
					<p>
						<span class="name">{{ params.name }}</span
						>고객님,<br />
						코웨이에 등록되어 있는 계약자 휴대폰 번호와<br />
						인증하신 휴대폰 번호가 상이합니다.<br /><br />
						휴대폰 번호를 최신 정보로 업데이트 하시겠습니까?
					</p>
				</div>

				<div class="u-btn-group">
					<div class="flexbox">
						<v-btn-toggle v-model="phoneUpdate">
							<v-btn large default>예</v-btn>
							<v-btn large default>아니오</v-btn>
						</v-btn-toggle>
					</div>
				</div>
			</div>
		</div>
		<!-- //contents -->
		<!-- bottom -->
		<div class="cw-app-radius">
			<div class="u-btns-group">
				<div class="column">
					<v-btn
						large
						depressed
						:disabled="btnDisabled"
						class="u-btn-active"
						@click="update()"
					>
						다음
					</v-btn>
				</div>
			</div>
		</div>
		<!-- //bottom -->
	</v-dialog>
</template>

<script>
export default {
	name: 'CustInfoUpdate',
	components: {},
	data() {
		return {
			popCustomerUpdate: false,
			phoneUpdate: 0,
			params: '',
			disabled: false,
			resolve: null,
			reject: null
		}
	},
	computed: {
		btnDisabled() {
			return this.phoneUpdate === undefined
		}
	},

	methods: {
		open(params) {
			this.popCustomerUpdate = true
			this.params = params
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		async update() {
			if (this.phoneUpdate === 0 || this.phoneUpdate === 1) {
				this.popCustomerUpdate = false
				if (this.phoneUpdate === 0) {
					this.resolve(true)
				} else {
					this.resolve(false)
				}
			} else {
				this.popCustomerUpdate = true
			}
		}
	}
}
</script>
